import React, { useEffect } from "react"
import Layout from "../../components/Layout"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Aos from "aos"
import Seo from "../../components/seo"
import SearchBar from "../../components/search/SearchBar"

const News = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      anchorPlacement: "top-bottom",
    })
  }, [])
  const data = useStaticQuery(query)
  const blogPosts = data.allContentfulBlog.nodes

  return (
    <Layout>
      <Seo title="News" />
      <div className="min-h-screen bg-gray-50 py-8 flex flex-col justify-center relative overflow-hidden lg:py-12">
        <div className="">
          <nav
            aria-label="Breadcrumb"
            className="max-w-2xl mx-auto py-6  flex justify-between items-center flex-col md:flex-row space-x-2 lg:max-w-7xl md:px-4 "
          >
            <ul className="max-w-2xl  flex items-center space-x-2  lg:max-w-7xl ">
              <Link
                to="/"
                className=" text-sm font-medium text-gray-900 hover:text-gray-600"
              >
                Home
              </Link>
              <svg
                width={16}
                height={20}
                viewBox="0 0 16 20"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="w-4 h-5 text-gray-300"
              >
                <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
              </svg>
              <Link to="/news" className="text-sm font-medium text-sky-600">
                News
              </Link>
            </ul>
            <SearchBar />
          </nav>

          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8  py-12 bg-white shadow-xl shadow-slate-700/10 ring-1 ring-gray-900/5 ">
            <h2 className="mb-6 text-xl md:text-3xl font-extrabold text-sky-900 uppercase tracking-wider">
              Ultime news
            </h2>
            <div
              data-aos="fade-up"
              className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-6 "
            >
              {blogPosts.map(post => {
                const { id, title, description, date, image, slug } = post
                const pathToImage = getImage(image)
                return (
                  <Link to={`/news/${slug}`} key={id}>
                    <div className="group mb-6 relative  p-4 border border-gray-200 rounded-lg">
                      <div className="relative w-full h-80 bg-white rounded-lg overflow-hidden group-hover:opacity-75">
                        <GatsbyImage
                          image={pathToImage}
                          alt={title}
                          className="w-full h-full object-center object-cover"
                        />
                      </div>
                      <h3 className="mt-6  text-base font-semibold text-gray-900">
                        <span className="absolute inset-0" />
                        {title}
                      </h3>
                      <p className="my-2 text-sm text-gray-500">{date}</p>
                      <p className="text-base text-gray-500 line-clamp-4">
                        {description}
                      </p>
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default News

const query = graphql`
  {
    allContentfulBlog(sort: {fields: createdAt, order: DESC}) {
      nodes {
        id
        image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
        title
        description
        date(formatString: "DD-MM-YYYY")
        slug
      }
    }
  }
`
